<template>
  <div class="index-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          :model="queryParam"
          @submit.native.prevent
        >
          <el-form-item label="名称">
            <el-input v-model="queryParam.name" placeholder="输入名称" />
          </el-form-item>

          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="getList"
            >
              查询
            </el-button>
            <el-button icon="el-icon-refresh-left" @click="searchReset">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>

      <vab-query-form-top-panel>
        <el-form ref="form" :inline="true">
          <el-form-item>
            <el-button icon="el-icon-plus" type="primary" @click="add">
              新增
            </el-button>
            <el-button
              icon="el-icon-delete"
              native-type="submit"
              type="danger"
              @click="batchDel"
            >
              删除
            </el-button>
          </el-form-item>

          <el-form-item />
        </el-form>
      </vab-query-form-top-panel>
    </vab-query-form>

    <el-table
      v-loading="loading"
      border
      :data="dataSource"
      stripe
      @select="onSelectChange"
      @select-all="onSelectChange"
    >
      <el-table-column align="center" type="selection" width="50" />
      <el-table-column align="center" label="序号" width="50">
        <template #default="{ $index }">
          {{ $index + 1 }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="名称 " prop="name" />
      <el-table-column align="center" label="图标 ">
        <template slot-scope="scope">
          <el-image
            v-if="scope.row.icon"
            :preview-src-list="[scope.row.icon]"
            :src="scope.row.icon"
            style="width: 50px; height: 50px"
          />
        </template>
      </el-table-column>

      <el-table-column align="center" fixed="right" label="操作" width="80">
        <template slot-scope="scope">
          <el-button size="mini" type="text" @click="edit(scope.row)">
            修改
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>

    <Dialog
      :height="dailog.height"
      :model-value="dailog.visible"
      :showfoot="dailog.showfoot"
      :title="dailog.title"
      :visible="dailog.visible"
      :width="dailog.width"
      @handleCloseDialog="handleClose"
      @handleConfirm="handleConfirm"
    >
      <template #content>
        <el-form
          ref="editParam"
          label-width="100px"
          :model="editParam"
          size="small"
        >
          <el-row>
            <el-form-item label="名称">
              <el-input
                v-model="editParam.name"
                placeholder="请输入名称"
                style="max-width: 300px"
              />
            </el-form-item>

            <el-form-item label="icon图标" prop="icon">
              <JMUpload
                v-model="editParam.icon"
                :multiple="false"
                :number="1"
                @change="handleChange"
              />
            </el-form-item>

            <el-form-item label="备注" prop="remark">
              <el-input
                v-model="editParam.remark"
                placeholder="请输入备注"
                style="max-width: 300px"
              />
            </el-form-item>
          </el-row>
        </el-form>
      </template>
    </Dialog>
  </div>
</template>

<script>
  import { BasePostMixin } from '@/mixins/BasePostMixin'
  import Dialog from '@/components/dialog.vue'
  import JMUpload from '@/components/jm-upload'
  export default {
    name: 'HouseAllocationIndex',
    components: {
      Dialog,
      JMUpload,
    },
    mixins: [BasePostMixin],
    data() {
      return {
        dailog: {
          height: 300,
          title: '商户参数配置',
          visible: false,
          width: '500px',
          showfoot: true,
        },
        defaultImg: require('@/assets/default.jpg'),
        editParam: {},
        disabled: false,
        loading: false,
        direction: 'rtl',
        size: '',
        selection: [],
        approvalList: [
          {
            label: '待审核',
            value: 3,
          },
          {
            label: '已通过',
            value: 1,
          },
          {
            label: '未通过',
            value: 2,
          },
        ],
        url: {
          list: '/admin/appliance/page',
          add: '/admin/appliance/save',
          handleEditUrl: '/admin/appliance/update',
          deleteBatch: '/admin/appliance/delete',
        },
      }
    },
    created() {},
    methods: {
      add() {
        this.dailog.visible = true
        this.dailog.title = '商户参数配置'
        this.dailog.showfoot = true
        this.editParam = {}
      },
      handleChange(value) {
        console.log('value', value)
        this.editParam.icon = value
      },
      //查询
      getList() {
        this.loadData()
      },
      handView(row) {
        console.log('row', row)
        this.drawer = true
        this.drawerData = row
      },
      //刷新
      refresh() {
        this.loadData(1)
      },

      edit(row) {
        this.dailog.visible = true
        this.dailog.title = '商户参数配置'
        this.dailog.showfoot = true
        this.$nextTick(() => {
          this.editParam = JSON.parse(JSON.stringify(row))
        })
      },
      // 确认新增
      handleConfirm() {
        console.log(this.editParam)
        this.editParam.type = 0
        if (this.editParam.id) {
          this.handEdit(this.editParam)
        } else {
          this.handleAdd()
        }
      },
      handleClose() {
        this.dailog.visible = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  .el-range-editor.el-input__inner {
    width: 105%;
  }

  .el-dialog .el-form-item__label {
    width: 150px !important;
  }

  ::v-deep(.container) {
    padding: 0 20px;
  }

  .card-title {
    margin-bottom: 18px;
    font-size: 16px;
    font-weight: bold;
  }

  .form {
    ::v-deep(.el-form-item--small.el-form-item) {
      margin-bottom: 5px;
    }
  }
</style>
