<template>
  <div class="index-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          :model="queryParam"
          @submit.native.prevent
        >
          <el-form-item label="小区名称">
            <el-input
              v-model="queryParam.communityName"
              placeholder="输入小区名称"
            />
          </el-form-item>
          <el-form-item label="楼栋名称" label-width="90px">
            <el-input
              v-model="queryParam.buildingName"
              placeholder="输入楼栋名称"
            />
          </el-form-item>

          <el-form-item label="房号名称" label-width="90px">
            <el-input
              v-model="queryParam.houseName"
              placeholder="输入房号名称"
            />
          </el-form-item>

          <el-form-item label="商户名称" label-width="90px">
            <el-input
              v-model="queryParam.shopName"
              placeholder="输入商户名称"
            />
          </el-form-item>

          <el-form-item label="提交时间">
            <el-date-picker
              v-model="houseCreateTime"
              placeholder="选择日期"
              style="width: 100%"
              type="daterange"
              value-format="yyyy-MM-dd"
              @change="submmitTime"
            />
          </el-form-item>

          <el-form-item label="审核状态" label-width="90px">
            <el-select v-model="queryParam.approvalStatus" clearable>
              <el-option
                v-for="item in approvalList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="getList"
            >
              查询
            </el-button>
            <el-button
              icon="el-icon-refresh-left"
              @click="searchReset({ daterange: 'houseCreateTime' })"
            >
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>

      <vab-query-form-top-panel>
        <el-form ref="form" :inline="true">
          <el-form-item>
            <el-button
              icon="el-icon-success"
              native-type="submit"
              type="success"
              @click="batchPass"
            >
              批量通过
            </el-button>

            <el-button
              icon="el-icon-close"
              native-type="submit"
              type="warning"
              @click="batchRejected"
            >
              批量驳回
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-delete"
              native-type="submit"
              type="danger"
              @click="batchDel"
            >
              批量删除
            </el-button>
          </el-form-item>

          <el-form-item />
        </el-form>
      </vab-query-form-top-panel>
    </vab-query-form>

    <el-table
      v-loading="loading"
      border
      :data="dataSource"
      stripe
      @select="onSelectChange"
      @select-all="onSelectChange"
    >
      <el-table-column align="center" type="selection" width="50" />
      <el-table-column align="center" label="商户名称" prop="shopName" />
      <el-table-column
        align="center"
        label="小区名称 "
        prop="communityName"
        width="180"
      />

      <el-table-column
        align="center"
        label="楼栋名称"
        prop="buildingName"
        width="180"
      />
      <el-table-column align="center" label="房间号" prop="houseName" />
      <el-table-column
        align="center"
        label="房源状态"
        prop="houseStatus"
        width="180"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.houseStatus == 1" type="success">
            空房可租
          </el-tag>

          <el-tag v-if="scope.row.houseStatus == 3">在租中</el-tag>

          <el-tag v-if="scope.row.houseStatus == 2" type="danger">
            空房不可租
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="提交时间"
        prop="createTime"
        width="180"
      />
      <el-table-column
        align="center"
        label="审核状态"
        prop="approvalStatus"
        width="180"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.approvalStatus == 0">未审核</el-tag>
          <el-tag v-if="scope.row.approvalStatus == 1" type="success">
            已通过
          </el-tag>

          <el-tag v-if="scope.row.approvalStatus == 2" type="danger">
            未通过
          </el-tag>

          <el-tag v-if="scope.row.approvalStatus == 3" size="mini">
            审核中
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="120">
        <template slot-scope="scope">
          <!-- <el-button
            :disabled="scope.row.approvalStatus != 1"
            size="mini"
            type="text"
            @click="setHot(scope.row)"
          >
            {{ scope.row.hotFlag == 1 ? '取消热门' : '设置热门' }}
          </el-button> -->
          <el-button size="mini" type="text" @click="seeDetail(scope.row)">
            详情
          </el-button>

          <el-button size="mini" type="text" @click="handleAudit(scope.row)">
            审批
          </el-button>

          <el-button
            size="mini"
            type="text"
            @click="handleDelete(scope.row.id)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>

    <Pagination
      :limit.sync="queryParam.pageSize"
      :page.sync="queryParam.pageNumber"
      :total-count="totalCount"
      @pagination="getList"
    />

    <Dialog
      :height="dailog.height"
      :model-value="dailog.visible"
      :showfoot="dailog.showfoot"
      :title="dailog.title"
      :visible="dailog.visible"
      :width="dailog.width"
      @handleCloseDialog="handleCloseDialog"
      @handleConfirm="handlePass"
    >
      <template #content>
        <el-form
          ref="formData"
          label-width="100px"
          :model="formData"
          size="small"
        >
          <div class="card-title">房源基本信息</div>
          <el-descriptions border class="margin-top" :column="2" size="medium">
            <el-descriptions-item>
              <template slot="label">房间名称</template>
              {{ formData.publishName }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">所属小区</template>
              {{ formData.communityName }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">商户名称</template>
              {{ formData.shopName }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">房源状态</template>
              {{ covHouseStatus(formData.houseStatus) }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">房源ID</template>
              {{ formData.houseId }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">房型</template>
              {{ formData.houseShape }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">业主联系人1</template>
              {{ formData.phone }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">业主联系人2</template>
              {{ formData.contactPhone || '' }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">所属地区</template>
              {{
                formData.province
                  ? formData.province
                  : '' + formData.city + formData.district
              }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">所在楼层</template>
              {{ formData.floor }}层
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">详细地区</template>
              {{ formData.address }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">租客广场</template>
              {{ formData.publishStatus == 1 ? '已添加' : '未添加' }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">面积</template>
              {{ formData.area }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">租金</template>
              {{ formData.publishRentPrice }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">朝向</template>
              {{ formData.toward ? formData.toward : '' }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">房源类型</template>
              {{ houseTypeFilter(formData.houseType) || '--' }}
            </el-descriptions-item>
          </el-descriptions>

          <el-row>
            <el-row>
              <el-divider />
            </el-row>
            <div v-if="formData.housePhotoList?.length > 0" class="card-title">
              房源照片
            </div>
            <el-descriptions
              border
              class="margin-top"
              :column="1"
              size="medium"
            >
              <el-descriptions-item
                v-for="(item, idx) in formData.housePhotoList"
                :key="idx"
              >
                <template slot="label">{{ item.placeTag }}</template>
                <img :src="item.url" style="width: 300px; height: 300px" />
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">备注</template>
                {{ formData.remark }}
              </el-descriptions-item>
            </el-descriptions>
          </el-row>
        </el-form>
      </template>
      <template #footer v-if="showAudit">
        <el-button
          :disabled="formData.approvalStatus == 2"
          @click="handleRejected"
        >
          驳 回
        </el-button>
        <el-button
          :disabled="formData.approvalStatus == 1"
          type="primary"
          @click="handlePass"
        >
          通 过
        </el-button>
      </template>
    </Dialog>
  </div>
</template>

<script>
  import Pagination from '@/components/pagination.vue'
  import { BasePostMixin } from '@/mixins/BasePostMixin'
  import Dialog from '@/components/auditDialog.vue'
  import {
    setHot,
    publishDetail,
    passOrRefuse,
    batchPassOrRefuse,
  } from '@/api/feature/feature'
  // import { getHousePublish } from '@/api/feature/newFeature'
  export default {
    name: 'HouseAuditIndex',
    components: {
      Pagination,
      Dialog,
    },
    mixins: [BasePostMixin],
    data() {
      return {
        disableMixinCreated: true,
        houseCreateTime: null,
        queryParam: {
          communityName: '',
          buildingName: '',
          houseName: '',
          shopName: '',
          approvalStatus: '',
        },
        dailog: {
          height: 500,
          title: '详情',
          visible: false,
          width: '800px',
          showfoot: false,
        },
        showAudit: true,
        defaultImg: require('@/assets/default.jpg'),
        formData: {},
        disabled: false,
        loading: false,
        direction: 'rtl',
        size: '',
        selection: [],
        approvalList: [
          {
            label: '待审核',
            value: 3,
          },
          {
            label: '已通过',
            value: 1,
          },
          {
            label: '未通过',
            value: 2,
          },
        ],
        url: {
          list: '/admin/house/publish/page',
          // list: '/house/publish',
          delete: '/admin/house/publish/deleteById',
          deleteBatch: '/admin/house/publish/delete',
        },
      }
    },
    created() {},
    mounted() {
      if (this.$route.query?.status == 3) {
        this.queryParam.approvalStatus = 3
      }
      this.loadData()
    },
    methods: {
      //转换房源类型 0-租房，1-商铺，2-公寓，3-厂房
      houseTypeFilter(type) {
        const houseType = {
          0: '租房',
          1: '商铺',
          2: '公寓',
          3: '厂房',
        }
        return houseType[type]
      },
      submmitTime() {
        if (this.houseCreateTime) {
          this.queryParam.houseCreateStartTime = this.houseCreateTime[0]
          this.queryParam.houseCreateEndTime = this.houseCreateTime[1]
        } else {
          delete this.queryParam.houseCreateStartTime
          delete this.queryParam.houseCreateEndTime
        }
      },
      covHouseStatus(i) {
        //0-未审核 1-已通过 2-未通过 3-审核中
        if (i == 1) {
          return '空房可租'
        } else if (i == 2) {
          return '空房不可租'
        } else if (i == 3) {
          return '已租'
        }
      },
      handleClose() {
        this.drawer = false
      },
      //查询
      getList() {
        this.loadData()
      },
      handView(row) {
        console.log('row', row)
        this.drawer = true
        this.drawerData = row
      },
      //刷新
      refresh() {
        this.loadData(1)
      },

      seeDetail(row) {
        this.dailog.visible = true
        this.dailog.title = '详情'
        this.dailog.showfoot = false
        this.showAudit = false
        publishDetail({ id: row.id }).then((res) => {
          console.log('res recode', res)
          this.formData = res.data
        })
      },
      handleCloseDialog() {
        this.dailog.visible = false
      },
      handleAudit(row) {
        this.dailog.visible = true
        this.dailog.title = '审批'
        this.dailog.showfoot = true
        this.showAudit = true
        publishDetail({ id: row.id }).then((res) => {
          console.log('res recode', res)
          this.formData = res.data
        })
      },
      batchRejected() {
        if (this.selectedRowKeys.length <= 0) {
          this.$message.warning('请选择一条记录！')
          return
        } else {
          //TODO SomeThings
          var ids = ''
          for (var a = 0; a < this.selectedRowKeys.length; a++) {
            ids += this.selectedRowKeys[a].id + ','
          }
          const params = {
            ids: ids,
            approvalStatus: 3,
          }
          batchPassOrRefuse(params).then((res) => {
            if (res.code === 0) {
              this.$message.success('已驳回！')
              this.loadData()
            }
          })
        }
      },

      batchPass() {
        if (this.selectedRowKeys.length <= 0) {
          this.$message.warning('请选择一条记录！')
          return
        } else {
          //TODO SomeThings
          var ids = ''
          for (var a = 0; a < this.selectedRowKeys.length; a++) {
            ids += this.selectedRowKeys[a].id + ','
          }
          const params = {
            ids: ids,
            approvalStatus: 1,
          }
          batchPassOrRefuse(params).then((res) => {
            if (res.code === 0) {
              this.$message.success('审核成功！')
              this.loadData()
            }
          })
        }
      },
      // 确认新增
      handlePass() {
        const params = {
          id: this.formData.id,
          approvalStatus: 1,
          remark: this.formData.remark,
        }
        console.log('params', params)
        passOrRefuse(params).then((res) => {
          console.log('passOrRefuse', res)
          if (res.code === 0) {
            this.$message.success('审核成功！')
            this.dailog.visible = false
            this.loadData()
          }
        })
      },
      handleRejected() {
        const params = {
          id: this.formData.id,
          approvalStatus: 2,
          remark: this.formData.remark,
        }
        passOrRefuse(params).then((res) => {
          console.log('passOrRefuse', res)
          if (res.code === 0) {
            this.$message.success('已驳回！')
            this.dailog.visible = false
            this.loadData()
          }
        })
      },
      setHot(v) {
        console.log('v', v)
        const params = {
          id: v.id,
          hotFlag: v.hotFlag == 1 ? 0 : 1,
        }
        setHot(params).then((res) => {
          if (res.code == 0) {
            this.$message.success('设置成功！')
            this.loadData(1)
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .el-range-editor.el-input__inner {
    width: 105%;
  }

  .el-dialog .el-form-item__label {
    width: 150px !important;
  }

  ::v-deep(.container) {
    padding: 0 20px;
  }

  .card-title {
    font-size: 16px;
    margin-bottom: 18px;
    font-weight: bold;
  }

  .form {
    ::v-deep(.el-form-item--small.el-form-item) {
      margin-bottom: 5px;
    }
  }
</style>
