var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index-container" },
    [
      _c(
        "vab-query-form",
        [
          _c(
            "vab-query-form-top-panel",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { inline: true, model: _vm.queryParam },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "小区名称" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "输入小区名称" },
                        model: {
                          value: _vm.queryParam.communityName,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryParam, "communityName", $$v)
                          },
                          expression: "queryParam.communityName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "楼栋名称", "label-width": "90px" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "输入楼栋名称" },
                        model: {
                          value: _vm.queryParam.buildingName,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryParam, "buildingName", $$v)
                          },
                          expression: "queryParam.buildingName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "房号名称", "label-width": "90px" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "输入房号名称" },
                        model: {
                          value: _vm.queryParam.houseName,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryParam, "houseName", $$v)
                          },
                          expression: "queryParam.houseName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商户名称", "label-width": "90px" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "输入商户名称" },
                        model: {
                          value: _vm.queryParam.shopName,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryParam, "shopName", $$v)
                          },
                          expression: "queryParam.shopName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "提交时间" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          placeholder: "选择日期",
                          type: "daterange",
                          "value-format": "yyyy-MM-dd",
                        },
                        on: { change: _vm.submmitTime },
                        model: {
                          value: _vm.houseCreateTime,
                          callback: function ($$v) {
                            _vm.houseCreateTime = $$v
                          },
                          expression: "houseCreateTime",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "审核状态", "label-width": "90px" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "" },
                          model: {
                            value: _vm.queryParam.approvalStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParam, "approvalStatus", $$v)
                            },
                            expression: "queryParam.approvalStatus",
                          },
                        },
                        _vm._l(_vm.approvalList, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-search",
                            "native-type": "submit",
                            type: "primary",
                          },
                          on: { click: _vm.getList },
                        },
                        [_vm._v(" 查询 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { icon: "el-icon-refresh-left" },
                          on: {
                            click: function ($event) {
                              return _vm.searchReset({
                                daterange: "houseCreateTime",
                              })
                            },
                          },
                        },
                        [_vm._v(" 重置 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "vab-query-form-top-panel",
            [
              _c(
                "el-form",
                { ref: "form", attrs: { inline: true } },
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-success",
                            "native-type": "submit",
                            type: "success",
                          },
                          on: { click: _vm.batchPass },
                        },
                        [_vm._v(" 批量通过 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-close",
                            "native-type": "submit",
                            type: "warning",
                          },
                          on: { click: _vm.batchRejected },
                        },
                        [_vm._v(" 批量驳回 ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-delete",
                            "native-type": "submit",
                            type: "danger",
                          },
                          on: { click: _vm.batchDel },
                        },
                        [_vm._v(" 批量删除 ")]
                      ),
                    ],
                    1
                  ),
                  _c("el-form-item"),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { border: "", data: _vm.dataSource, stripe: "" },
          on: { select: _vm.onSelectChange, "select-all": _vm.onSelectChange },
          scopedSlots: _vm._u([
            {
              key: "empty",
              fn: function () {
                return [
                  _c("el-image", {
                    staticClass: "vab-data-empty",
                    attrs: {
                      src: require("@/assets/empty_images/data_empty.png"),
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", type: "selection", width: "50" },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "商户名称", prop: "shopName" },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "小区名称 ",
              prop: "communityName",
              width: "180",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "楼栋名称",
              prop: "buildingName",
              width: "180",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "房间号", prop: "houseName" },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "房源状态",
              prop: "houseStatus",
              width: "180",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.houseStatus == 1
                      ? _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v(" 空房可租 "),
                        ])
                      : _vm._e(),
                    scope.row.houseStatus == 3
                      ? _c("el-tag", [_vm._v("在租中")])
                      : _vm._e(),
                    scope.row.houseStatus == 2
                      ? _c("el-tag", { attrs: { type: "danger" } }, [
                          _vm._v(" 空房不可租 "),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "提交时间",
              prop: "createTime",
              width: "180",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "审核状态",
              prop: "approvalStatus",
              width: "180",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.approvalStatus == 0
                      ? _c("el-tag", [_vm._v("未审核")])
                      : _vm._e(),
                    scope.row.approvalStatus == 1
                      ? _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v(" 已通过 "),
                        ])
                      : _vm._e(),
                    scope.row.approvalStatus == 2
                      ? _c("el-tag", { attrs: { type: "danger" } }, [
                          _vm._v(" 未通过 "),
                        ])
                      : _vm._e(),
                    scope.row.approvalStatus == 3
                      ? _c("el-tag", { attrs: { size: "mini" } }, [
                          _vm._v(" 审核中 "),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              fixed: "right",
              label: "操作",
              width: "120",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.seeDetail(scope.row)
                          },
                        },
                      },
                      [_vm._v(" 详情 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleAudit(scope.row)
                          },
                        },
                      },
                      [_vm._v(" 审批 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelete(scope.row.id)
                          },
                        },
                      },
                      [_vm._v(" 删除 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("Pagination", {
        attrs: {
          limit: _vm.queryParam.pageSize,
          page: _vm.queryParam.pageNumber,
          "total-count": _vm.totalCount,
        },
        on: {
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryParam, "pageSize", $event)
          },
          "update:page": function ($event) {
            return _vm.$set(_vm.queryParam, "pageNumber", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _c("Dialog", {
        attrs: {
          height: _vm.dailog.height,
          "model-value": _vm.dailog.visible,
          showfoot: _vm.dailog.showfoot,
          title: _vm.dailog.title,
          visible: _vm.dailog.visible,
          width: _vm.dailog.width,
        },
        on: {
          handleCloseDialog: _vm.handleCloseDialog,
          handleConfirm: _vm.handlePass,
        },
        scopedSlots: _vm._u(
          [
            {
              key: "content",
              fn: function () {
                return [
                  _c(
                    "el-form",
                    {
                      ref: "formData",
                      attrs: {
                        "label-width": "100px",
                        model: _vm.formData,
                        size: "small",
                      },
                    },
                    [
                      _c("div", { staticClass: "card-title" }, [
                        _vm._v("房源基本信息"),
                      ]),
                      _c(
                        "el-descriptions",
                        {
                          staticClass: "margin-top",
                          attrs: { border: "", column: 2, size: "medium" },
                        },
                        [
                          _c(
                            "el-descriptions-item",
                            [
                              _c("template", { slot: "label" }, [
                                _vm._v("房间名称"),
                              ]),
                              _vm._v(
                                " " + _vm._s(_vm.formData.publishName) + " "
                              ),
                            ],
                            2
                          ),
                          _c(
                            "el-descriptions-item",
                            [
                              _c("template", { slot: "label" }, [
                                _vm._v("所属小区"),
                              ]),
                              _vm._v(
                                " " + _vm._s(_vm.formData.communityName) + " "
                              ),
                            ],
                            2
                          ),
                          _c(
                            "el-descriptions-item",
                            [
                              _c("template", { slot: "label" }, [
                                _vm._v("商户名称"),
                              ]),
                              _vm._v(" " + _vm._s(_vm.formData.shopName) + " "),
                            ],
                            2
                          ),
                          _c(
                            "el-descriptions-item",
                            [
                              _c("template", { slot: "label" }, [
                                _vm._v("房源状态"),
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.covHouseStatus(_vm.formData.houseStatus)
                                  ) +
                                  " "
                              ),
                            ],
                            2
                          ),
                          _c(
                            "el-descriptions-item",
                            [
                              _c("template", { slot: "label" }, [
                                _vm._v("房源ID"),
                              ]),
                              _vm._v(" " + _vm._s(_vm.formData.houseId) + " "),
                            ],
                            2
                          ),
                          _c(
                            "el-descriptions-item",
                            [
                              _c("template", { slot: "label" }, [
                                _vm._v("房型"),
                              ]),
                              _vm._v(
                                " " + _vm._s(_vm.formData.houseShape) + " "
                              ),
                            ],
                            2
                          ),
                          _c(
                            "el-descriptions-item",
                            [
                              _c("template", { slot: "label" }, [
                                _vm._v("业主联系人1"),
                              ]),
                              _vm._v(" " + _vm._s(_vm.formData.phone) + " "),
                            ],
                            2
                          ),
                          _c(
                            "el-descriptions-item",
                            [
                              _c("template", { slot: "label" }, [
                                _vm._v("业主联系人2"),
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.formData.contactPhone || "") +
                                  " "
                              ),
                            ],
                            2
                          ),
                          _c(
                            "el-descriptions-item",
                            [
                              _c("template", { slot: "label" }, [
                                _vm._v("所属地区"),
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.formData.province
                                      ? _vm.formData.province
                                      : "" +
                                          _vm.formData.city +
                                          _vm.formData.district
                                  ) +
                                  " "
                              ),
                            ],
                            2
                          ),
                          _c(
                            "el-descriptions-item",
                            [
                              _c("template", { slot: "label" }, [
                                _vm._v("所在楼层"),
                              ]),
                              _vm._v(" " + _vm._s(_vm.formData.floor) + "层 "),
                            ],
                            2
                          ),
                          _c(
                            "el-descriptions-item",
                            [
                              _c("template", { slot: "label" }, [
                                _vm._v("详细地区"),
                              ]),
                              _vm._v(" " + _vm._s(_vm.formData.address) + " "),
                            ],
                            2
                          ),
                          _c(
                            "el-descriptions-item",
                            [
                              _c("template", { slot: "label" }, [
                                _vm._v("租客广场"),
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.formData.publishStatus == 1
                                      ? "已添加"
                                      : "未添加"
                                  ) +
                                  " "
                              ),
                            ],
                            2
                          ),
                          _c(
                            "el-descriptions-item",
                            [
                              _c("template", { slot: "label" }, [
                                _vm._v("面积"),
                              ]),
                              _vm._v(" " + _vm._s(_vm.formData.area) + " "),
                            ],
                            2
                          ),
                          _c(
                            "el-descriptions-item",
                            [
                              _c("template", { slot: "label" }, [
                                _vm._v("租金"),
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.formData.publishRentPrice) +
                                  " "
                              ),
                            ],
                            2
                          ),
                          _c(
                            "el-descriptions-item",
                            [
                              _c("template", { slot: "label" }, [
                                _vm._v("朝向"),
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.formData.toward
                                      ? _vm.formData.toward
                                      : ""
                                  ) +
                                  " "
                              ),
                            ],
                            2
                          ),
                          _c(
                            "el-descriptions-item",
                            [
                              _c("template", { slot: "label" }, [
                                _vm._v("房源类型"),
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.houseTypeFilter(
                                      _vm.formData.houseType
                                    ) || "--"
                                  ) +
                                  " "
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c("el-row", [_c("el-divider")], 1),
                          _vm.formData.housePhotoList?.length > 0
                            ? _c("div", { staticClass: "card-title" }, [
                                _vm._v(" 房源照片 "),
                              ])
                            : _vm._e(),
                          _c(
                            "el-descriptions",
                            {
                              staticClass: "margin-top",
                              attrs: { border: "", column: 1, size: "medium" },
                            },
                            [
                              _vm._l(
                                _vm.formData.housePhotoList,
                                function (item, idx) {
                                  return _c(
                                    "el-descriptions-item",
                                    { key: idx },
                                    [
                                      _c("template", { slot: "label" }, [
                                        _vm._v(_vm._s(item.placeTag)),
                                      ]),
                                      _c("img", {
                                        staticStyle: {
                                          width: "300px",
                                          height: "300px",
                                        },
                                        attrs: { src: item.url },
                                      }),
                                    ],
                                    2
                                  )
                                }
                              ),
                              _c(
                                "el-descriptions-item",
                                [
                                  _c("template", { slot: "label" }, [
                                    _vm._v("备注"),
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.formData.remark) + " "
                                  ),
                                ],
                                2
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            _vm.showAudit
              ? {
                  key: "footer",
                  fn: function () {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: { disabled: _vm.formData.approvalStatus == 2 },
                          on: { click: _vm.handleRejected },
                        },
                        [_vm._v(" 驳 回 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            disabled: _vm.formData.approvalStatus == 1,
                            type: "primary",
                          },
                          on: { click: _vm.handlePass },
                        },
                        [_vm._v(" 通 过 ")]
                      ),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }