<template>
  <div class="index-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          :model="queryParam"
          @submit.native.prevent
        >
          <el-form-item label="标签名称">
            <el-input
              v-model="queryParam.featureName"
              placeholder="输入标签名称"
            />
          </el-form-item>

          <el-form-item label="添加时间" label-width="100px">
            <el-date-picker
              v-model="queryParam.createTime"
              placeholder="选择日期"
              style="width: 100%"
              type="date"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>

          <el-form-item label="状态" label-width="100px">
            <el-select v-model="queryParam.status" clearable>
              <el-option
                v-for="item in statusList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              native-type="submit"
              type="primary"
              @click="queryData()"
            >
              查询
            </el-button>

            <el-button icon="el-icon-refresh-left" @click="searchReset">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
    </vab-query-form>

    <div class="content">
      <div class="left">
        <div class="table">
          <!--          <span>数据分类</span>-->
          <el-input
            v-model="tagGroupname"
            clearable
            placeholder="请输入标签名称"
            style="width: 220px; margin: 0 5px"
          />
          <el-button icon="el-icon-plus" type="primary" @click="addTagGoup">
            新增
          </el-button>
        </div>

        <div
          v-for="(item, idx) in tagGroup"
          :key="idx"
          class="tag-item"
          :class="{ active: idx === current }"
          @click="change(item, idx)"
        >
          <span>{{ item.groupName }}</span>
          <el-button
            icon="el-icon-close"
            type="text"
            @click="handleDeleteGroup(item.id)"
          />
        </div>
      </div>
      <div class="right">
        <div style="margin-bottom: 10px">
          <el-button icon="el-icon-plus" type="warning" @click="addTag()">
            添加标签
          </el-button>
        </div>
        <el-table v-loading="loading" border :data="data" stripe>
          <el-table-column align="center" label="标签ID" prop="id" />
          <el-table-column align="center" label="标签名称" prop="name" />
          <el-table-column
            align="center"
            label="标签使用数量"
            prop="useCount"
          />
          <el-table-column align="center" label="添加时间" prop="createTime" />
          <el-table-column align="center" label="状态" prop="status">
            <template slot-scope="scope">
              <el-tag
                size="mini"
                :type="scope.row.status == 1 ? 'danger' : 'success'"
                @click="handleDetail(scope.row)"
              >
                {{ scope.row.status == 1 ? '禁用' : '启用' }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            fixed="right"
            label="操作"
            width="100"
          >
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                @click="handleEdit(scope.row, 2)"
              >
                编辑
              </el-button>
              <el-button
                size="mini"
                type="text"
                @click="handleDelete(scope.row.id, 2)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
          <template #empty>
            <el-image
              class="vab-data-empty"
              :src="require('@/assets/empty_images/data_empty.png')"
            />
          </template>
        </el-table>
        <Pagination
          :limit.sync="queryParam.pageSize"
          :page.sync="queryParam.pageNumber"
          :total-count="totalCount"
          @pagination="getFeatureList"
        />
      </div>

      <Dialog
        :height="dailog.height"
        :model-value="dailog.visible"
        :title="dailog.title"
        :visible="dailog.visible"
        :width="dailog.width"
        @handleCloseDialog="handleCloseDialog"
        @handleConfirm="handleConfirm"
      >
        <template #content>
          <el-form
            ref="formData"
            label-width="100px"
            :model="formData"
            size="small"
          >
            <el-form-item label="标签名称" prop="featureName">
              <el-input
                v-model="formData.name"
                placeholder="请输入标签名称"
                style="max-width: 300px"
              />
            </el-form-item>

            <el-form-item :label="'标签图标'" prop="photoUrl">
              <JUpload
                v-if="formData.photoUrl != ''"
                ref="jupload"
                v-model="formData.photoUrl"
                :file-list="fileList"
                :multiple="true"
                :number="3"
                @change="handleChange"
              />
            </el-form-item>

            <el-form-item label="备注">
              <el-input
                v-model="formData.remark"
                placeholder="请输入备注"
                style="max-width: 300px"
              />
            </el-form-item>
          </el-form>
        </template>
      </Dialog>
    </div>
  </div>
</template>

<script>
  import Dialog from '@/components/dialog.vue'
  import JUpload from '@/components/j-upload'
  import Pagination from '@/components/pagination.vue'
  import {
    groupList,
    featureList,
    addTagGroup,
    addTag,
    editTag,
    delTag,
    queryTagDetail,
    deleteGroupTag,
  } from '@/api/feature/feature'

  export default {
    name: 'HouseTagIndex',
    components: {
      JUpload,
      Dialog,
      Pagination,
    },
    data() {
      return {
        dailog: {
          height: 300,
          title: '',
          visible: false,
          width: '500px',
        },
        formData: {},
        fileList: [],
        tagGroup: {},
        tagGroupname: '',
        current: 0,
        queryParam: {},
        listQuery: {},
        data: [],
        totalCount: 1,
        loading: false,
        statusList: [
          {
            label: '启用',
            value: 0,
          },
          {
            label: '未启用',
            value: 1,
          },
        ],
      }
    },
    mounted() {
      this.getTagGroupList()
    },
    methods: {
      handleChange(value) {
        console.log('value', value)
        this.formData.photoUrl = value
      },
      change(item, idx) {
        this.current = idx
        this.queryParam.groupId = item.id
        this.getFeatureList()
      },
      // 编辑
      handleEdit(row) {
        this.dailog.visible = true
        this.dailog.title = '编辑标签'
        queryTagDetail({ id: row.id })
          .then((res) => {
            this.formData = res.data
            this.fileList = []
            res.data.featurePhotoList.forEach((i) => {
              this.fileList.push({ url: i.photo })
            })
          })
          .finally(() => {
            this.$refs.jupload.callBackMethod()
          })
      },
      handleDelete(id) {
        this.$confirm('是否删除该标签?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          delTag({ id }).then((res) => {
            if (res.code == 0) {
              this.$message.success('删除成功！')
              this.getTagGroupList()
            }
          })
        })
      },
      handleDeleteGroup(id) {
        this.$confirm('是否删除该标签组?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          deleteGroupTag({ ids: id }).then((res) => {
            if (res.code == 0) {
              this.$message.success('删除成功！')
              this.current = 0
              this.getTagGroupList()
            }
          })
        })
      },
      queryData() {
        featureList(this.queryParam).then((res) => {
          this.data = res.data.records
        })
      },
      // 列表
      getTagGroupList() {
        groupList().then((res) => {
          this.tagGroup = res.data
          if (res.data.length > 0) {
            this.queryParam.groupId = res.data[0].id
            this.getFeatureList()
          }
        })
      },
      getFeatureList() {
        featureList(this.queryParam).then((res) => {
          const { records, total } = res.data
          this.data = records
          this.totalCount = +total
          console.log('res=>', res)
        })
      },
      addTag() {
        this.formData = {}
        this.fileList = []
        this.dailog.visible = true
        this.dailog.title = '添加标签'
      },
      addTagGoup() {
        if (
          this.tagGroupname.trim() == '' ||
          this.tagGroupname.trim() == null ||
          this.tagGroupname.trim() == 'null'
        ) {
          this.$message.error('请输入标签名称！')
        } else {
          addTagGroup({ groupName: this.tagGroupname }).then((res) => {
            if (res.code == 0) {
              this.$message.success('新增成功！')
              this.tagGroupname = ''
              this.getTagGroupList()
            }
          })
        }
      },
      handleCloseDialog() {
        this.dailog.visible = false
      },
      // 确认新增
      handleConfirm() {
        this.formData.groupId = this.queryParam.groupId
        console.log('this.formData', this.formData)
        if (this.formData.id) {
          editTag(this.formData).then((res) => {
            if (res.code == 0) {
              this.$message.success('更新成功！')
              this.dailog.visible = false
              this.getTagGroupList()
            }
          })
        } else {
          addTag(this.formData).then((res) => {
            if (res.code == 0) {
              this.$message.success('新增成功！')
              this.dailog.visible = false
              this.getTagGroupList()
            }
          })
        }
      },
      searchReset() {
        this.queryParam = {}
        this.getTagGroupList()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .index-container {
    .content {
      // background-color: aqua;
      display: flex;
      // box-sizing: border-box;
      .left {
        //flex: 1;
        width: 300px;
        padding: 20px 0;
        margin-right: 10px;
        //border: 1px solid #ccc;
        .table {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
        }

        .tag-item:hover {
          color: #1890ff !important;
          background-color: #e8f4ff !important;
          border-radius: 4px;
        }

        .tag-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 8px 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          vertical-align: middle;
          cursor: pointer;
          border-radius: 4px;
          &.active {
            color: #1890ff !important;
            background-color: #e8f4ff !important;
          }
        }
      }

      .right {
        flex: 1;
        width: 300px;
        padding: 20px;
        // padding-top: 20px;
        // padding-right: 20px;
        //border: 1px solid #ccc;
        .table {
          // display: flex;
          align-items: center;
          margin-bottom: 20px;
        }
      }
    }
  }

  :deep() {
    .el-table--border .el-table__cell:first-child .cell {
      padding-left: 0;
    }

    .el-table th.el-table__cell > .cell {
      padding-right: 0;
    }

    .el-table .cell {
      padding: 0;
    }

    .el-table--enable-row-hover .el-table__body tr:hover > td {
      cursor: pointer;
    }
  }
</style>
