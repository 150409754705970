<template>
  <IndexVue />
</template>
<script>
  import IndexVue from './index.vue'
  export default {
    name: 'HouseTagTag',
    components: {
      IndexVue,
    },
    data() {
      return {}
    },
    created() {},
  }
</script>
<style scoped lang="scss"></style>
